import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Feedback, Session, useApi } from '../../context/ApiContext';
import { AuthContext } from '../../context/AuthContext';
import Modal from '../../components/Feeds/Modal';

interface FeedbackControlProps {}

const FeedbackControl: React.FC<FeedbackControlProps> = () => {
  const { feedbacks, fetchFeedbacks, deleteFeedback, updateFeedback, sessions, courses  } = useApi();
  
  // Filter states
  const [sessionNumber, setSessionNumber] = useState<number | ''>('');
  const [sessionName, setSessionName] = useState<string>('');
  const [contentFilter, setContentFilter] = useState<string>('');
  const authContext = useContext(AuthContext);

  // Loading states
  const [loading, setLoading] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<number | null>(null);
  
  // Modal state
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(null);
  const [actionFeedbackMessage, setActionFeedbackMessage] = useState<string | null>(null);

  // Authentication handling
  useEffect(() => {
    if (!authContext) {
      window.location.href = '/login';
    }
  }, [authContext]);

  // Fetch sessions


  // Fetch feedbacks
  useEffect(() => {
    const fetchData = async () => {
      if (!authContext?.token?.access) return;

      setLoading(true);
      try {
        await fetchFeedbacks();
      } catch (error) {
        console.error('Error fetching feedbacks:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authContext?.token?.access]);

  // Filtered feedbacks
  const filteredFeedbacks = feedbacks.filter((feedback) => {
    const matchesSessionNumber = sessionNumber ? feedback.session === sessionNumber : true;
    const matchesSessionName = sessionName
      ? sessions.find((session) => session.id === feedback.session)?.title.toLowerCase().includes(sessionName.toLowerCase())
      : true;
    const matchesContent = contentFilter
      ? feedback.content.toLowerCase().includes(contentFilter.toLowerCase())
      : true;
    return matchesSessionNumber && matchesSessionName && matchesContent;
  });

  // Handle Delete
  const handleDelete = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this feedback?')) {
      setActionLoading(id);
      try {
        await deleteFeedback(id);
        setActionFeedbackMessage('Feedback deleted successfully!');
        // Re-fetch feedbacks after deletion
        await fetchFeedbacks();
      } catch (error) {
        console.error('Error deleting feedback:', error);
        setActionFeedbackMessage('Failed to delete feedback.');
      } finally {
        setActionLoading(null);
        setTimeout(() => setActionFeedbackMessage(null), 3000); // Clear message after 3 seconds
      }
    }
  };

  // Handle Edit
  const handleEdit = (feedback: Feedback) => {
    setSelectedFeedback(feedback);
    ;
    setModalOpen(true);
  };

  // Handle Save in Modal
  const handleSave = async (content: string, sessionId: number) => {
    if (selectedFeedback) {
      setLoading(true);
      try {
        await updateFeedback(selectedFeedback.id, { content }, sessionId);
        setActionFeedbackMessage('Feedback updated successfully!');
      } catch (error) {
        console.error('Error updating feedback:', error);
        setActionFeedbackMessage('Failed to update feedback.');
      } finally {
        setModalOpen(false);
        setSelectedFeedback(null);
        setLoading(false);
        setTimeout(() => setActionFeedbackMessage(null), 3000); // Clear message after 3 seconds
      }
    }
  };

  // Reset Filters
  const handleResetFilters = () => {
    setSessionNumber('');
    setSessionName('');
    setContentFilter('');
    fetchFeedbacks();
  };

  // Handle Refresh
  const handleRefresh = async () => {
    if (authContext?.token?.access) {
      setLoading(true);
      try {
        await fetchFeedbacks();
      } catch (error) {
        console.error('Error refreshing feedbacks:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Calculate statistics
  const totalFeedbackCount = feedbacks.length;
  interface StarProps {
    filled: boolean;
  }
  
  const Star: React.FC<StarProps> = ({ filled }) => (
    <span className={filled ? 'text-yellow-500' : 'text-gray-400'}>
      ★
    </span>
  );
  
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Header with Summary and Statistics */}
      <div className="mb-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Feedback Control</h1>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-2xl font-semibold mb-2">Statistics</h2>
          <p className="text-gray-600">Total Feedbacks: <span className="font-bold">{totalFeedbackCount}</span></p>
          {actionFeedbackMessage && (
            <p className="mt-2 text-green-600">{actionFeedbackMessage}</p>
          )}
        </div>
      </div>

      {/* Filters */}
      <div className="mb-8 bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold mb-4">Filters</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="sessionName" className="block text-gray-700">Session Name</label>
            <input
              id="sessionName"
              type="text"
              value={sessionName}
              onChange={(e) => setSessionName(e.target.value)}
              className="mt-1 block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md"
              placeholder="Enter session name"
            />
          </div>
          <div>
            <label htmlFor="sessionNumber" className="block text-gray-700">Session Number</label>
            <input
              id="sessionNumber"
              type="number"
              value={sessionNumber}
              onChange={(e) => setSessionNumber(Number(e.target.value) || '')}
              className="mt-1 block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md"
              placeholder="Enter session number"
            />
          </div>
          <div>
            <label htmlFor="contentFilter" className="block text-gray-700">Content Filter</label>
            <input
              id="contentFilter"
              type="text"
              value={contentFilter}
              onChange={(e) => setContentFilter(e.target.value)}
              className="mt-1 block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md"
              placeholder="Enter feedback content"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <button onClick={handleResetFilters} className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300 mr-2">Reset Filters</button>
          <button onClick={handleRefresh} className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300">Refresh Feedbacks</button>
        </div>
      </div>

      {/* Feedback List */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Feedbacks</h2>
        {loading ? (
          <p>Loading feedbacks...</p>
        ) : (
          <ul>
            {filteredFeedbacks.map((feedback) => (
              <li key={feedback.id} className="flex justify-between items-center py-2 border-b border-gray-200">
                <div>
                  <p className="font-semibold">{sessions.find((session) => session.id === feedback.session)?.title} - {feedback.owner}</p>
                  <p className="text-gray-600">{feedback.content}</p>
                  <p className="text-gray-400">{feedback.owner} -  {courses.find((session) => session.id === sessions.find((session) => session.id === feedback.session)?.course)?.title}  </p>
                  <p className="text-gray-400">
                  <div className="flex">
      {Array.from({ length: 5 }, (_, index) => (
        <Star key={index} filled={index < feedback.rating} />
      ))}
    </div>
                  </p>
                </div>
                <div>
                  <button
                    onClick={() => handleEdit(feedback)}
                    className="px-4 py-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(feedback.id)}
                    className="ml-2 px-4 py-1 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300"
                    disabled={actionLoading === feedback.id}
                  >
                    {actionLoading === feedback.id ? 'Deleting...' : 'Delete'}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Modal for Editing Feedback */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleSave}
        feedbackContent={selectedFeedback?.content || ''}
        sessionId={selectedFeedback?.session || 0}
      />
    </div>
  );
};

export default FeedbackControl;
