import React, { useEffect, useState } from "react";
import { Child } from "../../../../context/ApiContext";

interface ChildPreviewModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  children: Child[];
}

const ChildPreviewModal: React.FC<ChildPreviewModalProps> = ({
  isOpen,
  onRequestClose,
  children,
}) => {
  const [visibleChildren, setVisibleChildren] = useState<Child[]>([]);

  useEffect(() => {
    if (isOpen) {
      setVisibleChildren(children);
    }
    console.log("Child Preview Modal is open now " + children);
  }, [isOpen, children]);

  if (!isOpen || !visibleChildren.length) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={onRequestClose}
      ></div>
      <div className="bg-white rounded-lg shadow-xl p-6 z-10 w-full max-w-4xl mx-4 flex flex-wrap gap-4 max-h-[80vh] ">
        <h2 className="text-3xl font-semibold mb-6 text-center w-full">
          Children Preview
        </h2>
        <div className="flex flex-col flex-wrap gap-2 w-full h-full   overflow-auto max-h-[60vh] p-4  ">
          {visibleChildren.map((child, index) => (
            <div
              key={index}
              className="m-4 p-4 bg-gray-100 rounded-md shadow-md w-fit  "
            >
              <p className="mb-2 text-lg">
                <strong>Name:</strong> {child.name}
              </p>
              <p className="mb-2 text-lg">
                <strong>Age:</strong> {child.age}
              </p>
              <p className="mb-2 text-lg">
                <strong>Experience:</strong> {child.experience}
              </p>
              <p className="mb-2 text-lg">
                <strong>Username:</strong> {child.clear_username}
              </p>
              <p className="mb-2 text-lg">
                <strong>Password:</strong> {child.clear_password}
              </p>
              <p className="mb-2 text-lg">
                <strong>Parent ID:</strong> {child.parent}
              </p>
              
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4 w-full">
          <button
            className="bg-red-600 text-white px-6 py-3 rounded-md hover:bg-red-700 transition duration-200"
            onClick={onRequestClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChildPreviewModal;
