// components/ResetPasswordModal.tsx

import React from 'react';

interface ResetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  newPassword: string;
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
  confirmPassword: string;
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
  isProcessing: boolean;
  successMessage: string;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  isOpen,
  onClose,
onSubmit,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  isProcessing,
  successMessage,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
        <h3 className="text-lg font-bold mb-4">Reset Password</h3>
        {successMessage ? (
          <div className="bg-green-200 text-green-800 p-4 rounded mb-4">{successMessage}</div>
        ) : (
          <form>
            <div className="mb-4">
              <label className="block text-gray-600">New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full border px-4 py-2 rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-600">Confirm Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full border px-4 py-2 rounded"
              />
            </div>
            <div className="flex justify-between items-center">
              <button
                type="button"
                onClick={onSubmit}
                className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                disabled={isProcessing}
              >
                {isProcessing ? 'Processing...' : 'Reset Password'}
              </button>
              <button
                type="button"
                onClick={onClose}
                className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordModal;
