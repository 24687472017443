import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Subscription {
  id: number;
  start_date: string;
  end_date: string;
  subscription_type: "paid" | "gift";
  is_active: boolean;
}

interface ChildSubscriptionProps {
  childName: string;
}

const ChildSubscription: React.FC<ChildSubscriptionProps> = ({ childName }) => {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [subscriptionType, setSubscriptionType] = useState<"paid" | "gift">("paid");
  const [loading, setLoading] = useState(false); // New loading state

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await axios.get(`https://codeoceantech.pythonanywhere.com//api/subscriptions/?childName=${childName}`);
        setSubscription(response.data[0] || null);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      }
    };

    fetchSubscription();
  }, [childName]);

  const handleSave = async () => {
    setLoading(true); // Start loading when save begins
    try {
      if (subscription) {
        // Update existing subscription
        const response = await axios.put(`https://codeoceantech.pythonanywhere.com//api/subscriptions/${subscription.id}/`, {
          child: childName,
          start_date: startDate ? startDate.toISOString().split("T")[0] : null,
          end_date: endDate ? endDate.toISOString().split("T")[0] : null,
          subscription_type: subscriptionType,
        });
        setSubscription(response.data);
      } else {
        // Create new subscription
        const response = await axios.post(`https://codeoceantech.pythonanywhere.com//api/subscriptions/`, {
          child: childName,
          start_date: startDate ? startDate.toISOString().split("T")[0] : null,
          end_date: endDate ? endDate.toISOString().split("T")[0] : null,
          subscription_type: subscriptionType,
        });
        setSubscription(response.data);
      }
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error saving subscription:", error);
    } finally {
      setLoading(false); // Stop loading after the request is finished
    }
  };

  return (
    <div className="p-1 bg-white shadow-lg rounded-lg">
      <button
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={() => setIsModalOpen(true)}
        disabled={loading} // Disable the button when loading
      >
        {loading ? "Loading..." : subscription ? "Update Subscription" : "Add Subscription"}
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              {subscription ? "Update Subscription Details" : "Add New Subscription"}
            </h3>

            {subscription ? (
              <div>
                <p className="text-gray-600">
                  Current Subscription Type:{" "}
                  <span className="font-medium">{subscription.subscription_type}</span>
                </p>
                <p className="text-gray-600">
                  Current Start Date: <span className="font-medium">{subscription.start_date}</span>
                </p>
                <p className="text-gray-600">
                  Current End Date: <span className="font-medium">{subscription.end_date}</span>
                </p>

                {/* Update Fields */}
                <label className="block text-gray-600 mb-2 mt-4">Update Subscription Type</label>
                <select
                  value={subscriptionType}
                  onChange={(e) => setSubscriptionType(e.target.value as "paid" | "gift")}
                  className="mb-4 p-2 border rounded w-full"
                >
                  <option value="paid">Paid Subscription</option>
                  <option value="gift">Gift Subscription</option>
                </select>
              </div>
            ) : (
              <div>
                <label className="block text-gray-600 mb-2">Subscription Type</label>
                <select
                  value={subscriptionType}
                  onChange={(e) => setSubscriptionType(e.target.value as "paid" | "gift")}
                  className="mb-4 p-2 border rounded w-full"
                >
                  <option value="paid">Paid Subscription</option>
                  <option value="gift">Gift Subscription</option>
                </select>
              </div>
            )}

            <div className="flex items-center mb-4">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholderText="Select start date"
                dateFormat="yyyy-MM-dd"
              />
              <span className="mx-4 text-gray-500">to</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholderText="Select end date"
                dateFormat="yyyy-MM-dd"
              />
            </div>

            <button
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              onClick={handleSave}
              disabled={loading} // Disable the save button when loading
            >
              {loading ? "Saving..." : subscription ? "Update Subscription" : "Add Subscription"}
            </button>

            <div className="flex justify-between mt-4">
              <button
                className="mt-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                onClick={() => setIsModalOpen(false)}
                disabled={loading} // Optionally disable the close button while loading
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChildSubscription;
