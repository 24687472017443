import React, { useState } from 'react';
import { Instructor } from '../../../context/ApiContext';
import InstructorSessionsModal from '../../../components/common/InstructorSessionsModal';

interface InstructorListProps {
  instructors: Instructor[];
  onOpenModal: (instructorId: number) => void;
  onDeleteInstructor: (instructorId: number) => Promise<void>;
  onPreviewInstructor: (instructorId: number) => Promise<void>;
}

const InstructorList: React.FC<InstructorListProps> = ({
  instructors,
  onOpenModal,
  onDeleteInstructor,
  onPreviewInstructor,
}) => {
  const [selectedInstructorId, setSelectedInstructorId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Delete confirmation modal state

  const handleOpenModal = (instructorId: number) => {
    setSelectedInstructorId(instructorId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedInstructorId(null);
  };

  const handleDeleteClick = (instructorId: number) => {
    setSelectedInstructorId(instructorId); // Store the ID of the instructor to be deleted
    setIsDeleteModalOpen(true); // Show delete confirmation modal
  };

  const confirmDeleteInstructor = async () => {
    if (selectedInstructorId !== null) {
      await onDeleteInstructor(selectedInstructorId);
    }
    setIsDeleteModalOpen(false); // Close the delete modal after confirming
    setSelectedInstructorId(null); // Clear the selected instructor
  };

  const cancelDelete = () => {
    setIsDeleteModalOpen(false); // Close the modal without deleting
    setSelectedInstructorId(null); // Clear the selected instructor
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-200 text-gray-700">
            <th className="py-2 px-4 border-b">Instructor Name</th>
            <th className="py-2 px-4 border-b">Email</th>
            <th className="py-2 px-4 border-b">Phone</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {instructors.map((instructor) => (
            <tr key={instructor.id} className="hover:bg-gray-100">
              <td className="py-2 px-4 border-b">{instructor.user.username}</td>
              <td className="py-2 px-4 border-b">{instructor.user.email}</td>
              <td className="py-2 px-4 border-b">{instructor.user.phone_number}</td>
              <td className="py-2 px-4 border-b">
                <button
                  onClick={() => onPreviewInstructor(instructor.id)}
                  className="text-blue-500 hover:underline mr-2"
                >
                  Preview
                </button>
                <button
                  onClick={() => handleDeleteClick(instructor.id)}
                  className="text-red-500 hover:underline"
                >
                  Delete
                </button>
                <button
                  onClick={() => handleOpenModal(instructor.id)}
                  className="text-green-500 hover:underline ml-2"
                >
                  Edit Sessions
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && selectedInstructorId !== null && (
        <InstructorSessionsModal
          instructorId={selectedInstructorId}
          onClose={handleCloseModal}
        />
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-20">
          <div className="bg-white p-4 rounded-md shadow-md w-80">
            <h2 className="text-lg font-semibold mb-4">Confirm Delete</h2>
            <p>Are you sure you want to delete this instructor?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={cancelDelete}
                className="px-4 py-2 mr-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={confirmDeleteInstructor}
                className="px-4 py-2 text-white bg-red-500 rounded-md hover:bg-red-600"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstructorList;
