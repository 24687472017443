import React, { createContext, useState, useEffect, ReactNode, useCallback, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Define the interfaces for the context state
interface Child {
  name: string;
  age: number;
  experience: string;
  clear_username: string;
}

interface Parent {
  user: number;
  children: Child[];
}

interface UserInfo {
  username: string;
  phone_number: string;
  email: string;
  user_type: string;
}

interface Token {
  access: string;
  refresh: string;
}

interface AuthContextProps {
  user: string | null;
  token: Token | null;
  parent?: Parent | null;
  userInfo: UserInfo | null;
  role: string | null;
  loading: boolean;
  login: (token: Token, user: string, userInfo: UserInfo,  role: string) => void;
  logout: () => void;
  refreshToken: () => Promise<void>;
}

// Create the AuthContext with default null values
export const AuthContext = createContext<AuthContextProps | null>(null);

// AuthProvider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<string | null>(null);
  const [token, setToken] = useState<Token | null>(null);
  const [parent, setParent] = useState<Parent | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    const storedParent = localStorage.getItem('parent');
    const storedUserInfo = localStorage.getItem('user_info');
    const storedRole = localStorage.getItem('role');
  
    if (storedToken && storedToken !== 'undefined') {
      try {
        
        setToken(JSON.parse(storedToken));
      } catch (error:any) {
        localStorage.removeItem("token"); // Clears only the token
        localStorage.removeItem("user"); // Clears only the token
        localStorage.removeItem("parent"); // Clears only the token
        localStorage.removeItem("user_info"); // Clears only the token
        localStorage.removeItem("role"); // Clears only the token

      }
    }
    if (storedUser && storedUser !== 'undefined') {
      setUser(storedUser);
    }
    if (storedParent && storedParent !== 'undefined') {
      setParent(JSON.parse(storedParent));
    }
    if (storedUserInfo && storedUserInfo !== 'undefined') {
      setUserInfo(JSON.parse(storedUserInfo));
    }
    if (storedRole && storedRole !== 'undefined') {
      setRole(storedRole);
    }
  
    setLoading(false);
  }, []);

  const login = (token: Token, userData: string, userInfo: UserInfo, userRole: string) => {
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', userData);
    // localStorage.setItem('parent', JSON.stringify(parentData));
    localStorage.setItem('user_info', JSON.stringify(userInfo));
    localStorage.setItem('role', userRole);
    setToken(token);
    setUser(userData);
    // setParent(parentData);
    setUserInfo(userInfo);
    setRole(userRole);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    // localStorage.removeItem('parent');
    localStorage.removeItem('user_info');
    localStorage.removeItem('role');
    setToken(null);
    setUser(null);
    // setParent(null);
    setUserInfo(null);
    setRole(null);
  };

  const refreshToken = useCallback(async () => {
    try {
      const refresh = token?.refresh;
      if (!refresh) throw new Error('No refresh token available');

      const response = await axios.post('/api/token/refresh/', { refresh });
      const newToken = response.data.access;

      const updatedToken = {
        access: newToken,
        refresh,
      };

      localStorage.setItem('token', JSON.stringify(updatedToken));
      setToken(updatedToken);
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
    }
  }, [token, logout]);

  useEffect(() => {
    if (token) {
      const refreshInterval = setInterval(() => {
        refreshToken();
      }, 9 * 60 * 1000); // Refresh every 9 minutes

      return () => clearInterval(refreshInterval);
    }
  }, [token, refreshToken]);

  return (
    <AuthContext.Provider value={{ user, token,  userInfo, role, loading, login, logout, refreshToken }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined || context === null) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
  };