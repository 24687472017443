import React, { useState } from 'react';
import { Instructor, useApi } from '../../../context/ApiContext';
import InstructorForm from './InstructorForm';
import InstructorList from './InstructorList';
import InstructorSessionsModal from '../../../components/common/InstructorSessionsModal';
import Modal from './Modal';
import axios from 'axios';

const InstructorsTable: React.FC = () => {
  const { instructors, fetchInstructors } = useApi();
  const [selectedInstructor, setSelectedInstructor] = useState<number | null>(null);
  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [previewInstructor, setPreviewInstructor] = useState<Instructor | null>(null);

  const handleOpenModal = (instructorId: number) => {
    setSelectedInstructor(instructorId);
  };

  const handleInstructorAdded = () => {
    fetchInstructors(); // Refresh instructor list after adding
  };

  const handleDeleteInstructor = async (instructorId: number) => {
    try {
      await axios.delete(`https://codeoceantech.pythonanywhere.com//api/instructors/${instructorId}/`);
      fetchInstructors(); // Refresh instructor list after deletion
    } catch (error) {
      console.error('Error deleting instructor:', error);
    }
  };

  const handlePreviewInstructor = async (instructorId: number) => {
    try {
      const response = await axios.get(`https://codeoceantech.pythonanywhere.com//api/instructors/${instructorId}/`);
      setPreviewInstructor(response.data); // Set the instructor data for preview
    } catch (error) {
      console.error('Error fetching instructor details:', error);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-3xl font-semibold mb-6 text-gray-800">Instructors</h1>

      <button
        onClick={() => setShowAddForm(true)}
        className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 transition-colors mb-4"
      >
        Add New Instructor
      </button>

      {/* Modal for adding a new instructor */}
      <Modal isOpen={showAddForm} onClose={() => setShowAddForm(false)}>
        <InstructorForm onClose={() => setShowAddForm(false)} onInstructorAdded={handleInstructorAdded} />
      </Modal>

      <InstructorList
        instructors={instructors}
        onOpenModal={handleOpenModal}
        onDeleteInstructor={handleDeleteInstructor} // Pass delete function to the list
        onPreviewInstructor={handlePreviewInstructor} // Use the new function to preview instructor
      />

      {selectedInstructor && (
        <InstructorSessionsModal
          instructorId={selectedInstructor}
          onClose={() => setSelectedInstructor(null)}
        />
      )}

      {/* Preview Instructor Modal or Component */}
      {previewInstructor && (
        <Modal isOpen={true} onClose={() => setPreviewInstructor(null)}>
          <div>
            <h2>{previewInstructor.user.username}</h2>
            <p>Email: {previewInstructor.user.email}</p>
            <p>Phone: {previewInstructor.user.phone_number}</p>
            {/* Add more fields as needed */}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default InstructorsTable;
