import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { Bar, Doughnut } from "react-chartjs-2"; // Import Doughnut for chart
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SiNike } from "react-icons/si";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement, // Import ArcElement for Doughnut chart
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

interface Payment {
  id: string;
  amount: number;
  payment_status: string;
  expire_at: string;
  created_at: string;
  paymob_order_id?: string;
  paymob_reference_id?: string;
  paymob_payment_id?: string;
  updated_at: string;
  paymob_payment_link: string;
}

interface User {
  id: string;
  username: string;
}

interface StatusCount {
  payment_status: string;
  count: number;
}

const PaymentComponent: React.FC = () => {
  const auth = useContext(AuthContext); // Get user info and token
  const [amount, setAmount] = useState<number>(0);
  const [paymentLink, setPaymentLink] = useState<string | null>(null);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalPayments, setTotalPayments] = useState<number>(0);
  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [usersError, setUsersError] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null); // Selected user ID
  const [copied, setCopied] = useState<boolean>(false); // Track if link is copied

  const BASE_URL = "https://codeoceantech.pythonanywhere.com//api";

  const fetchUsers = async () => {
    setLoadingUsers(true);
    setUsersError(null);
    try {
      const response = await axios.get(`${BASE_URL}/accounts/users/`);
      setUsers(response.data);
    } catch (error) {
      setUsersError("Error fetching users");
    } finally {
      setLoadingUsers(false);
    }
  };

  const createPayment = async () => {
    if (!selectedUser) {
      setError("Please select a user to create a payment.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${BASE_URL}/payments/`,
        { amount, user: selectedUser },
        {
          headers: {
            Authorization: `Bearer ${auth?.token?.access}`,
          },
        }
      );
      setPaymentLink(response.data.paymob_payment_link);
      await fetchPayments(); // Refresh payments after creating one
    } catch (err: any) {
      setError(
        err.response?.data?.error ||
          "An error occurred while creating the payment"
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchPayments = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${BASE_URL}/payments/`, {
        headers: {
          Authorization: `Bearer ${auth?.token?.access}`,
        },
      });

      const { payments, total_amount, total_payments, status_counts } =
        response.data;
      setPayments(Array.isArray(payments) ? payments : []);
      setTotalAmount(total_amount);
      setTotalPayments(total_payments);
      setStatusCounts(status_counts);
    } catch (err: any) {
      setError(
        err.response?.data?.error || "An error occurred while fetching payments"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchPayments();
  }, []);

  const getStatusCount = (status: string) => {
    const statusCount = statusCounts.find((s) => s.payment_status === status);
    return statusCount ? statusCount.count : 0;
  };

  const updatePaymentStatus = async (paymentId: string, newStatus: string) => {
    setLoading(true);
    setError(null);
    try {
      await axios.put(
        `${BASE_URL}/payments/${paymentId}/`,
        { payment_status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${auth?.token?.access}`,
          },
        }
      );
      await fetchPayments();
    } catch (err: any) {
      setError(err.response?.data?.error || "Error updating payment status");
    } finally {
      setLoading(false);
    }
  };
  // Doughnut chart data for total, pending, and finished payments
  const doughnutData = {
    labels: ["Total Payments", "Pending", "Finished"],
    datasets: [
      {
        label: "Payment Status",
        data: [
          totalPayments,
          getStatusCount("PENDING"),
          getStatusCount("COMPLETED"),
        ],
        backgroundColor: ["#4CAF50", "#FF9800", "#03A9F4"],
      },
    ],
  };

  // Bar chart data
  const chartData = {
    labels: ["Total Payments", "Finished", "Pending", "Expired", "Failed"],
    datasets: [
      {
        label: "Payments Statistics",
        data: [
          totalPayments,
          getStatusCount("COMPLETED"),
          getStatusCount("PENDING"),
          getStatusCount("EXPIRED"),
          getStatusCount("FAILED"),
        ],
        backgroundColor: [
          "#4CAF50",
          "#3b82f6",
          "#f59e0b",
          "#f59eaa",
          "#aaaaa4",
        ],
      },
    ],
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Payments</h1>
      <div className="flex gap-3 justify-between mb-10 h-[480px] ">
        <div className="my-8 h-[390px]  ">
          <h2 className="text-xl font-semibold ">Payment Status Overview</h2>
          <Doughnut data={doughnutData} />
        </div>

        {/* Rest of the payment component */}
        <div className="h-[410px] w-full flex items-center justify-center  ">
          <Bar data={chartData} className=" w-64  h-[300px] max-h-[300px]  " />
        </div>
      </div>
      <div className="mt-8 my-8 h-[420px]">
        <h2 className="text-xl font-semibold">Statistics</h2>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="bg-gray-100 p-4 rounded shadow">
            <h3 className="text-lg font-medium">Total Payments</h3>
            <p className="text-xl">{totalPayments}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded shadow">
            <h3 className="text-lg font-medium">Total Amount</h3>
            <p className="text-xl">${totalAmount.toFixed(2)}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded shadow">
            <h3 className="text-lg font-medium">Failed Payments</h3>
            <p className="text-xl">{getStatusCount("FAILED")}</p>
          </div>
          <div className="bg-gray-100 p-4 rounded shadow">
            <h3 className="text-lg font-medium">Pending Payments</h3>
            <p className="text-xl">{getStatusCount("PENDING")}</p>
          </div>
        </div>
      </div>
      {/* Doughnut Chart for payment statuses */}
      <div className="flex flex-col mb-4">
        <select
          value={selectedUser || ""}
          onChange={(e) => setSelectedUser(e.target.value)}
          className="border border-gray-300 p-2 rounded mb-2"
        >
          <option value="" disabled>
            Select a user
          </option>
          {loadingUsers && <option>Loading users...</option>}
          {usersError && <option className="text-red-600">{usersError}</option>}
          {!loadingUsers &&
            users &&
            users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.username}
              </option>
            ))}
        </select>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
          placeholder="Enter amount"
          className="border border-gray-300 p-2 rounded mb-2"
        />
        <button
          onClick={createPayment}
          disabled={loading}
          className={`bg-blue-600 text-white py-2 rounded ${
            loading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
          }`}
        >
          {loading ? "Creating..." : "Create Payment"}
        </button>
      </div>
      {error && <p className="text-red-600">{error}</p>}
      {paymentLink && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold">Payment Link</h2>
          <a
            href={paymentLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Go to Payment
          </a>
        </div>
      )}
      <h2 className="text-xl font-semibold mt-8">Your Payments</h2>
      <table className="table-auto w-full mt-4">
        <thead>
          <tr>
            <th className="px-4 py-2">Amount</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">Created At</th>
            <th className="px-4 py-2">Expire At</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {payments.length > 0 ? (
            payments.map((payment) => (
              <tr key={payment.id} className="bg-white border-b">
                <td className="px-4 py-2">${payment.amount}</td>
                <td className="px-4 py-2">
                  {payment.payment_status}- ({payment.paymob_reference_id})
                </td>
                <td className="px-4 py-2">
                  {new Date(payment.created_at).toLocaleString()}
                </td>
                <td className="px-4 py-2">
                  {new Date(payment.expire_at).toLocaleString()}
                </td>
                <td className="px-4 py-2 flex">
                  {payment.payment_status.toLowerCase() === "completed" ? (
                    <div className="bg-green-100 text-black px-2 py-auto flex rounded">
                      <SiNike className="m-auto" />
                    </div>
                  ) : (
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                      onClick={() =>
                        updatePaymentStatus(payment.id, "COMPLETED")
                      }
                    >
                      Mark as Completed
                    </button>
                  )}
                  <CopyToClipboard
                    text={payment.paymob_payment_link}
                    onCopy={() => setCopied(true)}
                  >
                    <button className="bg-green-500 text-white px-4 py-2 rounded ml-2">
                      {copied ? "Copied!" : "Copy Payment Link"}
                    </button>
                  </CopyToClipboard>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="px-4 py-2" colSpan={5}>
                No payments found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentComponent;
