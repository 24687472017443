import React, { useState, useEffect } from "react";
import { Course, Session, useApi } from "../../context/ApiContext";
import SessionCard from "./SessionCard";
import ModalSession from "./ModalSesion";
import SessionForm from "./SessionForm";

interface CourseFormProps {
  onSubmit: (formData: Course, daysInWeak: number[]) => void;
  course?: Course | null; // Optional course prop for editing, or null for new courses
}

const CourseForm: React.FC<CourseFormProps> = ({ onSubmit, course}) => {
  const [sessionsCurrent, setSessionsCurrent] = useState<Session[]>([]);
  const [formData, setFormData] = useState<Course>({
    id: course?.id || 0, // Default id to 0 for new courses
    title: "",
    description: "",
    start_time: "",
    end_time: "",
    sessions: [], // Initialize sessions as an empty array
  });
  const [daysOfWeek, setDaysOfWeek] = useState<number[]>([]); // Separate state for daysOfWeek
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { sessions } = useApi();

  // Populate formData if editing a course
  useEffect(() => {
    if (course) {
      const courseSessions = sessions.filter(
        (session) => session.course === course.id
      );
      setSessionsCurrent(courseSessions);

      setFormData({
        id: course.id, // Include the id from the course
        title: course.title || "",
        description: course.description || "",
        start_time: course.start_time || "",
        end_time: course.end_time || "",
        sessions: course.sessions || [], // Populate sessions if editing
      });

      // If course has associated daysOfWeek, set it here (if applicable)
      // setDaysOfWeek(course.daysOfWeek || []);
    }
  }, [course, sessions]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDayChange = (dayIndex: number) => {
    setDaysOfWeek((prev) => {
      const newDays = [...prev];
      if (newDays.includes(dayIndex)) {
        return newDays.filter((day) => day !== dayIndex);
      } else {
        newDays.push(dayIndex);
        return newDays;
      }
    });
    console.log(daysOfWeek);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const completeFormData: Course = {
      ...formData,
      sessions: course?.sessions || [], // Default sessions to an empty array if editing
      // Optionally include daysOfWeek if needed
    };

    onSubmit(completeFormData,daysOfWeek);
  };

  const { fetchSessions, createSession } = useApi();

  const handleAddSession = (newSession: Session) => {
    createSession(newSession);
    fetchSessions();
    if (course) {
      const courseSessions = sessions.filter(
        (session) => session.course === course.id
      );
      setSessionsCurrent(courseSessions);
    }
    setSessionsCurrent((prev) => [...prev, newSession]);
    setIsModalOpen(false);
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-md mx-auto flex w-full h-full max-h-[86vh] gap-4">
      <div className="flex flex-col gap-4 overflow-y-auto max-h-[60vh] flex-1">
        {sessionsCurrent.length > 0 ? (
          sessionsCurrent.map((session) => (
            <SessionCard key={session.id} session={session} />
          ))
        ) : (
          <p className="text-center text-gray-500">No sessions available.</p>
        )}
      </div>
      <div className="flex-none w-1/3">
        <h2 className="text-2xl font-semibold mb-4">
          {course ? "Edit Course" : "Add New Course"}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium">Course Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border rounded-md"
              rows={3}
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Start Time</label>
            <input
              type="datetime-local"
              name="start_time"
              value={formData.start_time}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">End Time</label>
            <input
              type="datetime-local"
              name="end_time"
              value={formData.end_time}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Days of the Week</label>
            <div className="flex flex-wrap gap-4">
              {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                <div key={day} className="flex items-center">
                  <input
                    type="checkbox"
                    value={day}
                    checked={daysOfWeek.includes(day)}
                    onChange={() => handleDayChange(day)}
                    className="mr-2"
                  />
                  <label className="text-sm">
                    {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][day]}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
            >
              {course ? "Update Course" : "Add Course"}
            </button>
          </div>
          <button
            type="button"
            onClick={() => setIsModalOpen(true)}
            className="w-full bg-green-500 text-white p-2 rounded-md hover:bg-green-600 mt-4"
          >
            Add New Session
          </button>
        </form>
      </div>
      {isModalOpen && course && (
        <ModalSession onClose={() => setIsModalOpen(false)}>
          <SessionForm onSubmit={handleAddSession} courseId={course?.id} />
        </ModalSession>
      )}
    </div>
  );
};

export default CourseForm;
