import React from 'react';
import { useApi } from '../../../../context/ApiContext';

const ProgressOverview: React.FC = () => {
  const { child } = useApi(); // Access the data from the API context

  // Ensure `child` is an array or provide a default empty array
  const childData = Array.isArray(child) ? child : [];

  const get_untagged_child_to_courses = childData.filter((child) => child.courses.length <= 0);
  const get_tagged_child_to_courses = childData.filter((child) => child.courses.length >= 1);

  const totalRequests = get_untagged_child_to_courses.length + get_tagged_child_to_courses.length;
  const notCompletedCount = get_untagged_child_to_courses.length;
  const completedCount = get_tagged_child_to_courses.length;

  const progressData = [
    {
      title: 'Requests',
      value: `${totalRequests} Users`,
      percent: totalRequests > 0 ? (totalRequests / childData.length) * 100 : 0,
      color: 'bg-green-500',
    },
    {
      title: 'Not Completed',
      value: `${notCompletedCount} Users`,
      percent: totalRequests > 0 ? (notCompletedCount / totalRequests) * 100 : 0,
      color: 'bg-blue-500',
    },
    {
      title: 'Completed',
      value: `${completedCount} Users`,
      percent: totalRequests > 0 ? (completedCount / totalRequests) * 100 : 0,
      color: 'bg-yellow-500',
    },
  ];

  return (
    <div className="bg-white shadow rounded-lg p-4 mb-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {progressData.map((item, index) => (
          <div key={index} className="text-center">
            <div className="text-gray-600">{item.title}</div>
            <div className="font-semibold truncate">
              {item.value} ({item.percent.toFixed(2)}%)
            </div>
            <div
              className={`w-full h-2 mt-2 rounded-full ${item.color}`}
              style={{ width: `${item.percent}%` }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressOverview;
