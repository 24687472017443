import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Initialize Axios instance
const api = axios.create({
    baseURL: 'https://codeoceantech.pythonanywhere.com//api/', // Adjust based on your Django server
    headers: {
        'Content-Type': 'application/json',
    },
});

// TypeScript Interfaces
interface CountryStats {
    country_code: string;
    currency: string;
    deliveredTotal: number;   // Count of delivered notifications
    undeliveredTotal: number; // Count of undelivered notifications
    spent: number;            // Total spending for delivered notifications
}

interface Notify {
    id: number;
    child: number;
    child_name: string;
    message_type: string;
    content: string;
    created_at: string; // ISO date string
    delivered: boolean;
    phone_number: string;
    country_code: string;
}

const NotifyList: React.FC = () => {
    const [notifies, setNotifies] = useState<Notify[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [countryStats, setCountryStats] = useState<CountryStats[]>([]);

    // Filters
    const [messageType, setMessageType] = useState<string>('');
    const [delivered, setDelivered] = useState<string>('');
    const [search, setSearch] = useState<string>('');

    // Define the currency rates
    const CURRENCY_RATES: { [key: string]: { rate: number; currency: string } } = {
        '+20': { rate: 0.98, currency: 'EGP' }, // Egypt - EGP
        '+966': { rate: 0.13, currency: 'EUR' }, // Saudi Arabia - Euro
        '+971': { rate: 0.14, currency: 'EUR' }, // UAE - Euro
        '+965': { rate: 0.14, currency: 'EUR' }, // Kuwait - Euro
        '+974': { rate: 0.16, currency: 'EUR' }, // Qatar - Euro
        '+961': { rate: 0.20, currency: 'EUR' }, // Lebanon - Euro (most expensive)
        // Add more country rates here as needed
    };

    // Fetch Notifications
    const fetchNotifies = async () => {
        try {
            setLoading(true);
            setError('');
            const params: any = {};

            if (messageType) params.message_type = messageType;
            if (delivered) params.delivered = delivered;
            if (search) params.search = search;

            const response = await api.get<Notify[]>('notifing/notify/', { params });
            const data = response.data;
            setNotifies(data);

            // Compute country statistics for delivered and undelivered notifications separately
            const stats: { [key: string]: CountryStats } = {};

            data.forEach((notify) => {
                const countryCode = notify.country_code || 'Unknown';
                const rateInfo = CURRENCY_RATES[countryCode] || { rate: 0, currency: 'Unknown' };
                const spent = rateInfo.rate; // Assuming each delivered notification costs 'rate' units

                if (!stats[countryCode]) {
                    stats[countryCode] = {
                        country_code: countryCode,
                        currency: rateInfo.currency,
                        deliveredTotal: 0,
                        undeliveredTotal: 0,
                        spent: 0,
                    };
                }

                if (notify.delivered) {
                    stats[countryCode].deliveredTotal += 1;
                    stats[countryCode].spent += spent; // Only add to spending for delivered notifications
                } else {
                    stats[countryCode].undeliveredTotal += 1;
                }
            });

            setCountryStats(Object.values(stats));
        } catch (err) {
            console.error(err);
            setError('Failed to fetch notify records.');
        } finally {
            setLoading(false);
        }
    };

    // Fetch notifications on component mount and when filters change
    useEffect(() => {
        fetchNotifies();
    }, [messageType, delivered, search]);

    // Handler for applying filters
    const handleApplyFilters = () => {
        fetchNotifies();
    };

    // Handler for resetting filters
    const handleResetFilters = () => {
        setMessageType('');
        setDelivered('');
        setSearch('');
    };

    // Loading state
    if (loading) {
        return (
            <p className="text-center text-lg text-gray-500">Loading...</p>
        );
    }

    // Error state
    if (error) {
        return (
            <p className="text-center text-red-600">{error}</p>
        );
    }

    return (
        <div className="p-6 bg-white shadow rounded-lg max-w-7xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Notify List and Spending Summary</h2>

            {/* Country Statistics and Spending Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-6">
                {countryStats.map((stat) => (
                    <div key={stat.country_code} className="bg-indigo-100 p-4 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-gray-800">{stat.country_code}</h3>
                        <p className="text-sm text-gray-600">Delivered: {stat.deliveredTotal}</p>
                        <p className="text-sm text-gray-600">Undelivered: {stat.undeliveredTotal}</p>
                        <p className="text-sm text-gray-600">
                            Amount Spent (Delivered): {stat.spent.toFixed(2)} {stat.currency}
                        </p>
                    </div>
                ))}
            </div>

            {/* Filters */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                {/* Message Type Filter */}
                <div>
                    <label className="block text-gray-700 mb-1">Message Type</label>
                    <select
                        className="w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-indigo-200"
                        value={messageType}
                        onChange={(e) => setMessageType(e.target.value)}
                    >
                        <option value="">All</option>
                        <option value="welcome">Welcome</option>
                        <option value="reminder">Reminder</option>
                        <option value="subscription">Subscription</option>
                        {/* Add more message types as needed */}
                    </select>
                </div>

                {/* Delivered Filter */}
                <div>
                    <label className="block text-gray-700 mb-1">Delivered</label>
                    <select
                        className="w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-indigo-200"
                        value={delivered}
                        onChange={(e) => setDelivered(e.target.value)}
                    >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>

                {/* Search Filter */}
                <div>
                    <label className="block text-gray-700 mb-1">Search</label>
                    <input
                        type="text"
                        className="w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-indigo-200"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Child name or phone number"
                    />
                </div>
            </div>

            {/* Filter Buttons */}
            <div className="flex justify-end mb-6">
                <button
                    onClick={handleApplyFilters}
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 mr-2"
                >
                    Apply Filters
                </button>
                <button
                    onClick={handleResetFilters}
                    className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700"
                >
                    Reset Filters
                </button>
            </div>

            {/* Notifications Table */}
            <div className="mt-6 overflow-x-auto">
                <table className="w-full table-auto bg-white border border-gray-200 rounded-md shadow">
                    <thead>
                        <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">ID</th>
                            <th className="py-3 px-6 text-left">Child Name</th>
                            <th className="py-3 px-6 text-left">Message Type</th>
                            <th className="py-3 px-6 text-left">Content</th>
                            <th className="py-3 px-6 text-left">Created At</th>
                            <th className="py-3 px-6 text-left">Delivered</th>
                            <th className="py-3 px-6 text-left">Phone Number</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-700 text-sm">
                        {notifies.map((notify) => (
                            <tr key={notify.id} className="border-b border-gray-200 hover:bg-gray-50">
                                <td className="py-3 px-6 text-left">{notify.id}</td>
                                <td className="py-3 px-6 text-left">{notify.child_name}</td>
                                <td className="py-3 px-6 text-left">{notify.message_type}</td>
                                <td className="py-3 px-6 text-left">{notify.content}</td>
                                <td className="py-3 px-6 text-left">
                                    {new Date(notify.created_at).toLocaleString()}
                                </td>
                                <td className="py-3 px-6 text-left">{notify.delivered ? 'Yes' : 'No'}</td>
                                <td className="py-3 px-6 text-left">{notify.phone_number}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default NotifyList;
