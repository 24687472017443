import React, { useState } from 'react';
import { FaCaretDown, FaSpinner } from 'react-icons/fa';
import { MdCheck } from 'react-icons/md';

interface DropdownProps {
  selectedValues: string[];
  options: string[];
  onChange: (values: string[]) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ selectedValues, options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingOption, setLoadingOption] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [pendingOption, setPendingOption] = useState<string | null>(null); // Option awaiting confirmation

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOptionSelect = (value: string) => {
    setPendingOption(value); // Store the selected option in a temporary state
    setShowModal(true); // Show the confirmation modal
  };

  const confirmOptionToggle = async () => {
    if (pendingOption) {
      setLoadingOption(pendingOption);
      const updatedValues = selectedValues.includes(pendingOption)
        ? selectedValues.filter((v) => v !== pendingOption)
        : [...selectedValues, pendingOption];

      await new Promise((resolve) => setTimeout(resolve, 500));

      onChange(updatedValues);
      setLoadingOption(null);
    }
    setShowModal(false); // Hide the modal after confirmation
    setPendingOption(null); // Clear the pending option
  };

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative inline-block w-full text-left">
      <button
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={isOpen}
        className="flex items-center justify-between w-full px-4 py-2 text-gray-800 bg-white border border-gray-300 rounded-md shadow-md transition duration-300 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        {selectedValues.length > 0 ? selectedValues.join(', ') : 'Select options'}
        <FaCaretDown className="ml-2 h-5 w-5 text-gray-500 transition-transform duration-300" />
      </button>

      {isOpen && (
        <div
          className="absolute right-0 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10 max-h-60 overflow-y-auto transition-transform duration-300 ease-in-out"
          role="listbox"
        >
          <div className="p-2">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 mb-2 text-gray-700 border border-gray-300 rounded-md"
            />
          </div>
          {filteredOptions.map((option) => (
            <button
              key={option}
              onClick={() => handleOptionSelect(option)}
              role="option"
              aria-selected={selectedValues.includes(option)}
              className={`flex justify-between w-full px-4 py-2 mt-1 text-gray-700 transition-colors duration-200 ease-in-out rounded-md ${
                selectedValues.includes(option)
                  ? 'bg-blue-100 text-blue-700 font-semibold'
                  : 'hover:bg-gray-100'
              }`}
            >
              {option}
              {loadingOption === option ? (
                <FaSpinner className="animate-spin text-blue-600" />
              ) : (
                selectedValues.includes(option) && <MdCheck className="text-blue-600" />
              )}
            </button>
          ))}
        </div>
      )}

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-20 bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-md w-80">
            <h2 className="text-lg font-semibold mb-4">Confirm Selection</h2>
            <p>Are you sure you want to {selectedValues.includes(pendingOption!) ? 'remove' : 'add'} "{pendingOption}"?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 mr-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={confirmOptionToggle}
                className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
