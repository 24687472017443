import React, { useEffect, useState } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (content: string, sessionId: number) => void;
  feedbackContent: string;
  sessionId: number;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSave,
  feedbackContent,
  sessionId,
}) => {
  const [content, setContent] = useState(feedbackContent);
  const [sessionNumber, setSessionNumber] = useState(sessionId);
  useEffect(() => {
    console.log(feedbackContent);
    setContent(feedbackContent)
    setSessionNumber(sessionId)
  }, [sessionId])
  if (!isOpen) return null;


  const handleSave = () => {
    onSave(content, sessionNumber);
    onClose();
  };
;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="bg-white p-6 rounded-lg shadow-lg z-10">
        <h2 className="text-xl font-semibold mb-4">Edit Feedback</h2>
        <div className="mb-4">
          <label htmlFor="content" className="block text-gray-700">
            Content
          </label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="mt-1 block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md"
            rows={3}
          />
        </div>
        {/* <div className="mb-4">
          <label htmlFor="sessionNumber" className="block text-gray-700">Session Number</label>
          <input
            type="number"
            id="sessionNumber"
            value={sessionNumber}
            onChange={(e) => setSessionNumber(Number(e.target.value))}
            className="mt-1 block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md"
          />
        </div> */}
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-300 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded-md"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
