import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Problem, Session, useApi } from '../../context/ApiContext';
import { AuthContext } from '../../context/AuthContext';
import Modal from '../../components/Feeds/Modal';

interface ProblemControlProps {}

const ProblemControl: React.FC<ProblemControlProps> = () => {
  const { problems, fetchProblems, deleteProblem, updateProblem, sessions, courses } = useApi();

  // Filter states
  const [sessionNumber, setSessionNumber] = useState<number | ''>('');
  const [contentFilter, setContentFilter] = useState<string>('');
  const authContext = useContext(AuthContext);

  // Loading states
  const [loading, setLoading] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<number | null>(null);

  // Modal state
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedProblem, setSelectedProblem] = useState<Problem | null>(null);
  const [actionFeedbackMessage, setActionFeedbackMessage] = useState<string | null>(null);

  // Authentication handling
  useEffect(() => {
    if (!authContext) {
      window.location.href = '/login';
    }
  }, [authContext]);

  // Fetch problems
  useEffect(() => {
    const fetchData = async () => {
      if (!authContext?.token?.access) return;

      setLoading(true);
      try {
        await fetchProblems();
      } catch (error) {
        console.error('Error fetching problems:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authContext?.token?.access]);

  // Filtered problems
  const filteredProblems = problems.filter((problem) => {
    const matchesSessionNumber = sessionNumber ? problem.session === sessionNumber : true;
    const matchesContent = contentFilter
      ? problem.description.toLowerCase().includes(contentFilter.toLowerCase())
      : true;
    return matchesSessionNumber && matchesContent;
  });

  // Handle Delete
  const handleDelete = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this problem?')) {
      setActionLoading(id);
      try {
        await deleteProblem(id);
        setActionFeedbackMessage('Problem deleted successfully!');
        await fetchProblems(); // Re-fetch problems after deletion
      } catch (error) {
        console.error('Error deleting problem:', error);
        setActionFeedbackMessage('Failed to delete problem.');
      } finally {
        setActionLoading(null);
        setTimeout(() => setActionFeedbackMessage(null), 3000); // Clear message after 3 seconds
      }
    }
  };

  // Handle Edit
  const handleEdit = (problem: Problem) => {
    setSelectedProblem(problem);
    setModalOpen(true);
  };

  // Handle Save in Modal
  const handleSave = async (description: string, sessionId: number) => {
    if (selectedProblem) {
      setLoading(true);
      try {
        await updateProblem(selectedProblem.id, {
          description,
          session: selectedProblem.session,
        });
        setActionFeedbackMessage('Problem updated successfully!');
      } catch (error) {
        console.error('Error updating problem:', error);
        setActionFeedbackMessage('Failed to update problem.');
      } finally {
        setModalOpen(false);
        setSelectedProblem(null);
        setLoading(false);
        setTimeout(() => setActionFeedbackMessage(null), 3000); // Clear message after 3 seconds
      }
    }
  };

  // Reset Filters
  const handleResetFilters = () => {
    setSessionNumber('');
    setContentFilter('');
    fetchProblems();
  };

  // Handle Refresh
  const handleRefresh = async () => {
    if (authContext?.token?.access) {
      setLoading(true);
      try {
        await fetchProblems();
      } catch (error) {
        console.error('Error refreshing problems:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const totalProblemCount = problems.length;

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Header with Summary and Statistics */}
      <div className="mb-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Problem Control</h1>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-2xl font-semibold mb-2">Statistics</h2>
          <p className="text-gray-600">Total Problems: <span className="font-bold">{totalProblemCount}</span></p>
          {actionFeedbackMessage && (
            <p className="mt-2 text-green-600">{actionFeedbackMessage}</p>
          )}
        </div>
      </div>

      {/* Filters */}
      <div className="mb-8 bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold mb-4">Filters</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="sessionNumber" className="block text-gray-700">Session Number</label>
            <input
              id="sessionNumber"
              type="number"
              value={sessionNumber}
              onChange={(e) => setSessionNumber(Number(e.target.value) || '')}
              className="mt-1 block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md"
              placeholder="Enter session number"
            />
          </div>
          <div>
            <label htmlFor="contentFilter" className="block text-gray-700">Content Filter</label>
            <input
              id="contentFilter"
              type="text"
              value={contentFilter}
              onChange={(e) => setContentFilter(e.target.value)}
              className="mt-1 block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md"
              placeholder="Enter problem description"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={handleResetFilters}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300 mr-2"
          >
            Reset Filters
          </button>
          <button
            onClick={handleRefresh}
            className={`px-4 py-2 text-white rounded-md transition duration-300 ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
            disabled={loading}
          >
            {loading ? 'Refreshing...' : 'Refresh Problems'}
          </button>
        </div>
      </div>

      {/* Problem List */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Problems</h2>
        {loading ? (
          <div className="flex justify-center items-center">
            <p>Loading problems...</p>
            {/* Optionally replace this text with a spinner */}
          </div>
        ) : (
          <ul>
{filteredProblems.map((problem) => {
  const session = sessions?.find((session) => session.id === problem.session);
  const course = courses?.find((course) => course.id === session?.course);

  return (
    <li key={problem.id} className="flex justify-between items-center py-2 border-b border-gray-200">
      <div>
        <p className="font-semibold">
          {session?.title} - {session?.course} {course ? course.title : ''}
        </p>
        <p className="text-gray-600">{problem.description}</p>
        <p className="text-gray-400">{problem.owner}</p>
      </div>
      <div>
        <button
          onClick={() => handleEdit(problem)}
          className="px-4 py-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
        >
          Edit
        </button>
        <button
          onClick={() => handleDelete(problem.id)}
          className="ml-2 px-4 py-1 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300"
          disabled={actionLoading === problem.id}
        >
          {actionLoading === problem.id ? 'Deleting...' : 'Delete'}
        </button>
      </div>
    </li>
  );
})}

          </ul>
        )}
      </div>

      {/* Modal for Editing Problem */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleSave}
        feedbackContent={selectedProblem?.description || ''}
        sessionId={selectedProblem?.session || 0}
      />
    </div>
  );
};

export default ProblemControl;
