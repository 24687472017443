import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Session, useApi } from '../../context/ApiContext';

interface SessionCardProps {
  session: Session;
}

const SessionCard: React.FC<SessionCardProps> = ({ session }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<Partial<Session>>({
    title: session.title,
    description: session.description,
    start_time: session.start_time,
    end_time: session.end_time,
    meeting_link: session.meeting_link,
    is_Started: session.is_Started,
    is_ended: session.is_ended,
  });

  const { fetchSessions } = useApi(); // Access API context

  useEffect(() => {
    // Reset form data when session changes
    setFormData({
      title: session.title,
      description: session.description,
      start_time: session.start_time,
      end_time: session.end_time,
      meeting_link: session.meeting_link,
      is_Started: session.is_Started,
      is_ended: session.is_ended,
    });
  }, [session]);

  const updateSession = async (id: number, data: Partial<Session>) => {
    try {
      // Add course ID to the data
      const updatedData = { ...data, course: session.course };

      await axios.put(`https://codeoceantech.pythonanywhere.com//api/sessions/${id}/`, updatedData);
      fetchSessions(); // Refresh the list after update
    } catch (error) {
      console.error('Error updating session:', error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    updateSession(session.id, formData);
    setIsEditing(false);
  };
// console.log('fromsession:',session);
  return (
    <div className="bg-white shadow-lg rounded-lg border border-gray-200 h-full min-h-[430px] overflow-auto">
      <div className="p-4">
        {isEditing ? (
          <>
            <input
              type="text"
              name="title"
              value={formData.title || ''}
              onChange={handleChange}
              className="mb-2 p-2 border rounded w-full"
            />
            <textarea
              name="description"
              value={formData.description || ''}
              onChange={handleChange}
              className="mb-2 p-2 border rounded w-full"
              rows={3}
            />
            <input
              type="datetime-local"
              name="start_time"
              value={formData.start_time?.substring(0, 16) || ''}
              onChange={handleChange}
              className="mb-2 p-2 border rounded w-full"
            />
            <input
              type="datetime-local"
              name="end_time"
              value={formData.end_time?.substring(0, 16) || ''}
              onChange={handleChange}
              className="mb-2 p-2 border rounded w-full"
            />
            <input
              type="text"
              name="meeting_link"
              value={formData.meeting_link || ''}
              onChange={handleChange}
              className="mb-2 p-2 border rounded w-full"
            />
            <div className="flex items-center mb-4">
              <label className="mr-2">Status:</label>
              <select
                name="is_Started"
                value={formData.is_Started ? 'true' : 'false'}
                onChange={e => setFormData(prev => ({ ...prev, is_Started: e.target.value === 'true' }))}
                className="p-2 border rounded"
              >
                <option value="true">Started</option>
                <option value="false">Not Started</option>
              </select>
            </div>
            <div className="flex items-center mb-4">
              <label className="mr-2">Ended:</label>
              <select
                name="is_ended"
                value={formData.is_ended ? 'true' : 'false'}
                onChange={e => setFormData(prev => ({ ...prev, is_ended: e.target.value === 'true' }))}
                className="p-2 border rounded"
              >
                <option value="true">Ended</option>
                <option value="false">Not Ended</option>
              </select>
            </div>
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white p-2 rounded"
            >
              Save
            </button>
            <button
              onClick={() => setIsEditing(false)}
              className="bg-gray-500 text-white p-2 rounded ml-2"
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <h2 className="text-xl font-semibold text-gray-800 mb-2">{session.title}</h2>
            <p className="text-gray-600 mb-4">{session.description}</p>
            <div className="flex items-center mb-4">
              <span className="font-medium text-gray-800">Start Time:</span>
              <span className="ml-2 text-gray-600">{session.start_time && new Date(session.start_time).toLocaleString()}</span>
            </div>
            <div className="flex items-center mb-4">
              <span className="font-medium text-gray-800">End Time:</span>
              <span className="ml-2 text-gray-600">{ session.end_time &&  new Date(session.end_time).toLocaleString()}</span>
            </div>
            <div className="flex items-center mb-4">
              <span className="font-medium text-gray-800">Meeting Link:</span>
              <a href={session.meeting_link} className="ml-2 text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                {session.meeting_link}
              </a>
            </div>
            <div className="flex items-center mb-4">
              <span className="font-medium text-gray-800">Status:</span>
              <span className={`ml-2 px-2 py-1 text-xs font-semibold rounded-full ${session.is_Started ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                {session.is_Started ? 'Started' : 'Not Started'}
              </span>
            </div>
            <div className="flex items-center mb-4">
              <span className="font-medium text-gray-800">Ended:</span>
              <span className={`ml-2 px-2 py-1 text-xs font-semibold rounded-full ${session.is_ended ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                {session.is_ended ? 'Ended' : 'Not Ended'}
              </span>
            </div>
            <button
              onClick={() => setIsEditing(true)}
              className="bg-yellow-500 text-white p-2 rounded"
            >
              Edit
            </button>
          </>
        )}
      </div>
      <div className="bg-gray-100 p-4">
        <h3 className="text-lg font-semibold text-gray-800 mb-2">Course ID: {session.course}</h3>
        {/* Additional details can be added here */}
      </div>
    </div>
  );
};

export default SessionCard;
