import React, { useEffect, useState } from "react";
import { Child, useApi } from "../../context/ApiContext";
import ChildPreviewModal from "../base/home/components/ChildPreviewModal";

function Children() {
  const { child } = useApi();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredChildren, setFilteredChildren] = useState<Child[]>([]);
  const [selectedChild, setSelectedChild] = useState<Child | null>(null);
  const [isPreviewing, setIsPreviewing] = useState(false); // State for preview modal

  useEffect(() => {
    setFilteredChildren(
      child.filter((c) =>
        c.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, child]);

  const handlePreviewingChild = (child: Child) => {
    setSelectedChild(child);
    setIsPreviewing(true); // Show the modal
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">Manage Children</h1>

      <div className="mb-6">
        <input
          type="text"
          placeholder="Search for a child..."
          className="w-full p-2 border border-gray-300 rounded-md mb-4"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {filteredChildren.map((child, idx) => (
            <div key={idx} className="p-4 bg-gray-100 rounded-md shadow-md">
              <p className="mb-2"><strong>Name:</strong> {child.name}</p>
              <p className="mb-2"><strong>Age:</strong> {child.age}</p>
              <p className="mb-2"><strong>Experience:</strong> {child.experience}</p>
              <p className="mb-2"><strong>Username:</strong> {child.clear_username}</p>
              
            </div>
          ))}
        </div>
      </div>


    </div>
  );
}

export default Children;
