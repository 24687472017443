import React, { useState } from 'react';
import { User } from '../views/pages/UserProfile';

interface UserTableProps {
  users: User[];
  onEdit: (user: User) => void;
  onResetPassword: (user: User) => void;
}

const UserTable: React.FC<UserTableProps> = ({ users, onEdit, onResetPassword }) => {
  // New state for search
  const [searchTerm, setSearchTerm] = useState('');

  // Filter users based on the search term
  const filteredUsers = users.filter(user =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.phone_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">All Users</h2>

      {/* Search Input */}
      <div className="mb-4">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by username, phone number, or email"
          className="p-2 border rounded w-full"
        />
      </div>

      <table className="table-auto w-full bg-white shadow-lg">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">Username</th>
            <th className="px-4 py-2">Phone Number</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">User Type</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id} className="border-b">
              <td className="px-4 py-2">{user.username}</td>
              <td className="px-4 py-2">{user.phone_number}</td>
              <td className="px-4 py-2">{user.email || 'N/A'}</td>
              <td className="px-4 py-2 capitalize">{user.user_type}</td>
              <td className="px-4 py-2">
                <button
                  onClick={() => onEdit(user)}
                  className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => onResetPassword(user)}
                  className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded"
                >
                  Reset Password
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
