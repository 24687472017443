import React, { useContext, useEffect, useState } from "react";
import CourseForm from "../../components/common/CourseForm";
import { Child, Session, useApi } from "../../context/ApiContext";
import { Course } from "../../context/ApiContext";
import ChildPreviewModal from "../base/home/components/ChildPreviewModal";

function ParentComponentCourses() {
  const { courses, createCourse, updateCourse, createSession, child } =
    useApi();
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [selectedChildren, setSelectedChildren] = useState<Child[] >(child);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false); // State for preview modal

  const handleAdd = () => {
    setIsAdding(true);
    setSelectedCourse(null); // Reset the form for adding a new course
  };
  const handlePreviewingChildren = (course:Course) => {
    setSelectedCourse(course)
    setIsPreviewing(!isPreviewing); // Show the modal
  };
  const handleEdit = (course: Course) => {
    setIsEditing(true);
    setSelectedCourse(course);
  };
  const handleSubmit = async (courseData: Course, daysInWeek: number[]) => {
    console.log("days in week parent", daysInWeek);

    if (isEditing) {
      await updateCourse(courseData.id, courseData);
      setIsEditing(false);
      setSelectedCourse(null); // Reset selected course
    } else {
      const newCourse: any = await createCourse(courseData); // Expect newCourse to be Course | null
      console.log(newCourse.id); // Log the new course ID

      // Get start and end dates from the course
      const startDate = new Date(courseData.start_time); // Use course start_time
      const endDate = new Date(courseData.end_time); // Use course end_time

      // Calculate the total duration in weeks
      const timeDifference = endDate.getTime() - startDate.getTime();
      const weeksToGenerate = Math.ceil(
        timeDifference / (1000 * 3600 * 24 * 7)
      ); // Convert milliseconds to weeks

      // Loop through the specified days in the week
      for (let week = 0; week < weeksToGenerate; week++) {
        for (const day of daysInWeek) {
          // Calculate the session date based on the start date and week offset
          const sessionDate = new Date(startDate);
          sessionDate.setDate(
            startDate.getDate() +
              week * 7 +
              ((day - startDate.getDay() + 7) % 7)
          );

          // Check if the session date is within the course duration
          if (sessionDate >= startDate && sessionDate <= endDate) {
            const sessionData: Session = {
              id: 0, // Temporary ID until created
              title: `Session for ${sessionDate.toLocaleString()}`, // Current date time as title
              description: "Default description",
              start_time: `${sessionDate.toISOString().split("T")[0]}T14:00:00`, // Set to 2 PM on the specified day
              end_time: `${sessionDate.toISOString().split("T")[0]}T15:00:00`, // Set end time as needed
              meeting_link: "", // Provide a link if necessary
              course: newCourse.id, // Associate with the created course
              attendance: [], // Default empty attendance
              is_Started: false,
              is_ended: false,
              absent: [],
              exact_start_date: sessionDate.toISOString().split("T")[0], // Format as needed
              exact_end_date: sessionDate.toISOString().split("T")[0], // Format as needed
            };

            await createSession(sessionData); // Create the session
          }
        }
      }

      setIsAdding(false);
    }
  };
  useEffect(() => {
    let selectedChildInCourse : Child[] = []
  child?.map((child) => {
    if (typeof selectedCourse?.id === 'number' && child.courses.includes(selectedCourse?.id)) {
        selectedChildInCourse.push(child);
    }
  })
    setSelectedChildren(selectedChildInCourse);
  }, [selectedCourse]);

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">Manage Courses</h1>

      {/* List of courses */}
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Current Courses</h2>
        <table className="min-w-full bg-white shadow-md rounded-md">
          <thead>
            <tr>
              <th className="border-b p-2 text-left">Title</th>
              <th className="border-b p-2 text-left">Start Time</th>
              <th className="border-b p-2 text-left">End Time</th>
              <th className="border-b p-2 text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course, idx) => (
              <tr key={idx}>
                <td className="border-b p-2">{course.title}</td>
                <td className="border-b p-2">{course.start_time}</td>
                <td className="border-b p-2">{course.end_time}</td>
                <td className="border-b p-2">{course.all_child}</td>
                <td className="border-b p-2 text-right flex gap-2">
                  <button
                    className="bg-yellow-500 text-white p-2 rounded-md hover:bg-yellow-600 mr-2"
                    onClick={() => handleEdit(course)}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-yellow-500 text-white p-2 rounded-md hover:bg-yellow-600 mr-2"
                    onClick={() => handlePreviewingChildren(course)}
                  >
                    preview children
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add or Edit course form */}
      <div className="mb-6">
        {!isAdding && !isEditing && (
          <button
            className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
            onClick={handleAdd}
          >
            Add New Course
          </button>
        )}

        {(isAdding || isEditing) && (
          <CourseForm course={selectedCourse} onSubmit={handleSubmit} />
        )}
      </div>
      <ChildPreviewModal
        isOpen={isPreviewing}
        onRequestClose={() => setIsPreviewing(false)}
        children={selectedChildren}
      />
    </div>
  );
}

export default ParentComponentCourses;
