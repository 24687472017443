// src/context/ApiContext.tsx

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios, { AxiosInstance } from 'axios';

// Define the types for the context data
interface ApiContextType {
  parents: Parent[];
  child: Child[];
  instructors: Instructor[];
  courses: Course[];
  sessions: Session[];
  tasks: Task[];
  taskCompletions: TaskCompletion[];
  feedbacks: Feedback[];
  problems: Problem[];
  fetchParents: () => void;
  fetchChildren: () => void;
  fetchInstructors: () => void;
  fetchCourses: () => void;
  fetchSessions: () => void;
  fetchSession: (num:number) => void;
  fetchTasks: () => void;
  fetchTaskCompletions: () => void;
  fetchFeedbacks: () => void;
  fetchProblems: () => void;
  createParent: (data: Parent) => void;
  createChildren: (data: Child) => void;
  createInstructor: (data: Instructor) => void;
  createCourse: (data: Course) => void;
  createSession: (data: Session) => void;
  createTask: (data: Task) => void;
  createTaskCompletion: (data: TaskCompletion) => void;
  createFeedback: (data: Feedback) => void;
  createProblem: (data: Problem) => void;
  updateParent: (id: number, data: Partial<Parent>) => void;
  updateChildren: (id: number, data: Partial<Child>) => void;
  updateInstructor: (id: number, data: Partial<Instructor>) => void;
  updateCourse: (id: number, data: Partial<Course>) => void;
  updateSession: (id: number, data: Partial<Session>) => void;
  updateTask: (id: number, data: Partial<Task>) => void;
  updateTaskCompletion: (id: number, data: Partial<TaskCompletion>) => void;
  updateFeedback: (id: number, data: Partial<Feedback>, sessionId: number) => void;
  updateProblem: (id: number, data: Partial<Problem>) => void;
  deleteParent: (id: number) => void;
  deleteChildren: (id: number) => void;
  deleteInstructor: (id: number) => void;
  deleteCourse: (id: number) => void;
  deleteSession: (id: number) => void;
  deleteTask: (id: number) => void;
  deleteTaskCompletion: (id: number) => void;
  deleteFeedback: (id: number) => void;
  deleteProblem: (id: number) => void;
}
export interface User {
    id: number;
    username : string;
    phone_number : string;
    user_type : string;
    email: string;
    country_code?: string;
  }
  export interface Child {
    id: number;
    parent: number;
    name: string;
    clear_password: string;
    clear_username: string;
    experience: string;
    age: number;
    is_active?:false
    courses: number[];
    user?: User;
    // Add other relevant fields
  }
// Define the data types
export  interface Parent {
  id: number;
  user: User;
}

export  interface Instructor {
  id: number;
  user: User;
  // Other fields as needed
}

export interface Course {
  id: number;
  all_child?: number;
  title: string;
  description: string;
  start_time: string;
  end_time: string;
  sessions: Session[]
}

export interface Session {
  id: number;
  // course: Course;
  title: string;
  description: string;
  meeting_link: string;
  course: number;
  instructor_attended?: number;
  attendance: Child[];
  is_Started: boolean;
  is_ended: boolean;
  absent: Child[];
  start_time: string;
  end_time: string;
  exact_start_date: string;
  exact_end_date: string;
}

export interface Task {
  id: number;
  session: Session;
  title: string;
  is_alive: boolean;
  description: string;
  start_time: string;
  duration_days: number;
  duration_minutes: number;
  completed: Child[];
}

export interface TaskCompletion {
  id: number;
  child?: Child;
  task: Task;
  degree: number;
}

export interface Feedback {
  id: number;
  session: number;
  submitted_by: any;
  created_by: any;
  content: string;
  rating: number;
  owner: string;
  created_at: string;
}

export interface Problem {
  id: number;
  session: number;
  description: string;
  reported_by: any;
  created_at: string;
  owner: string;
}

// Create the context
const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [parents, setParents] = useState<Parent[]>([]);
  const [child, setChild] = useState<Child[]>([]);
  const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskCompletions, setTaskCompletions] = useState<TaskCompletion[]>([]);
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [problems, setProblems] = useState<Problem[]>([]);
  const [token, setToken] = useState<string | null>(localStorage.getItem('authToken'));

  // Create an Axios instance
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: 'https://codeoceantech.pythonanywhere.com//api/',
  });

  // Set the Authorization header for every request if token exists
  useEffect(() => {
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      localStorage.setItem('authToken', token);
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
      localStorage.removeItem('authToken');
    }
  }, [token]);
  // Fetch functions
  const fetchParents = async () => {
    try {
      const response = await axios.get('https://codeoceantech.pythonanywhere.com//api/parents/');
      setParents(response.data);
    } catch (error) {
      console.error('Error fetching parents:', error);
    }
  };
  const fetchChildren = async () => {
    try {
      const response = await axios.get('https://codeoceantech.pythonanywhere.com//api/children/');
      setChild(response.data);
    } catch (error) {
      console.error('Error fetching parents:', error);
    }
  };

  const fetchInstructors = async () => {
    try {
      const response = await axios.get('https://codeoceantech.pythonanywhere.com//api/instructors/');
      setInstructors(response.data);
    } catch (error) {
      console.error('Error fetching instructors:', error);
    }
  };

  const fetchCourses = async () => {
    try {
      const response = await axios.get('https://codeoceantech.pythonanywhere.com//api/courses-control/');
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchSessions = async () => {
    try {
      const response = await axios.get('https://codeoceantech.pythonanywhere.com//api/sessions/');
      setSessions(response.data);
    } catch (error) {
      console.error('Error fetching sessions:', error);
    }
  };
  const fetchSession = async (num: number): Promise<Session | null> => {
    try {
      const response = await axios.get(`https://codeoceantech.pythonanywhere.com//api/sessions/${num}/sessions`);
      return response.data; // Assuming response.data is of type Session
    } catch (error) {
      console.error('Error fetching session:', error);
      return null; // Return null if there's an error
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get('https://codeoceantech.pythonanywhere.com//api/tasks/');
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const fetchTaskCompletions = async () => {
    try {
      const response = await axios.get('https://codeoceantech.pythonanywhere.com//api/task-completions/');
      setTaskCompletions(response.data);
    } catch (error) {
      console.error('Error fetching task completions:', error);
    }
  };

  const fetchFeedbacks = async () => {
    try {
      const response = await axiosInstance.get<Feedback[]>('feedback/');
      setFeedbacks(response.data);
    } catch (error) {
      console.error('Error fetching feedbacks:', error);
    }
  };

  const fetchProblems = async () => {
    try {
      const response = await axios.get('https://codeoceantech.pythonanywhere.com//api/problems/');
      setProblems(response.data);
    } catch (error) {
      console.error('Error fetching problems:', error);
    }
  };

  // CRUD functions
  const createParent = async (data: any) => {
    try {
      await axios.post('https://codeoceantech.pythonanywhere.com//api/create-parent/', data);
      fetchParents(); // Refresh the list after creation
    } catch (error) {
      console.error('Error creating parent:', error);
    }
  };
  const createChildren = async (data: Child) => {
    try {
      await axios.post('https://codeoceantech.pythonanywhere.com//api/children/', data);
      fetchChildren(); // Refresh the list after creation
    } catch (error) {
      console.error('Error creating parent:', error);
    }
  };

  const createInstructor = async (data: Instructor) => {
    try {
      await axios.post('https://codeoceantech.pythonanywhere.com//api/instructors/', data);
      fetchInstructors(); // Refresh the list after creation
    } catch (error) {
      console.error('Error creating instructor:', error);
    }
  };


  const createCourse = async (data: Course): Promise<Course | null> => {
      try {
          const response = await axios.post('https://codeoceantech.pythonanywhere.com//api/courses-control/', data);
          fetchCourses(); // Refresh the list after creation
          return response.data; // Ensure this returns the correct course data
      } catch (error) {
          console.error('Error creating course:', error);
          return null; // Return null on error
      }
  };
  


  const createSession = async (data: Session) => {
    try {
      await axios.post('https://codeoceantech.pythonanywhere.com//api/sessions/', data);
      fetchSessions(); // Refresh the list after creation
    } catch (error) {
      console.error('Error creating session:', error);
    }
  };

  const createTask = async (data: Task) => {
    try {
      await axios.post('https://codeoceantech.pythonanywhere.com//api/tasks/', data);
      fetchTasks(); // Refresh the list after creation
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  const createTaskCompletion = async (data: TaskCompletion) => {
    try {
      await axios.post('https://codeoceantech.pythonanywhere.com//api/task-completions/', data);
      fetchTaskCompletions(); // Refresh the list after creation
    } catch (error) {
      console.error('Error creating task completion:', error);
    }
  };

  const createFeedback = async (data: Feedback) => {
    try {
      await axios.post('https://codeoceantech.pythonanywhere.com//api/feedbacks/', data);
      fetchFeedbacks(); // Refresh the list after creation
    } catch (error) {
      console.error('Error creating feedback:', error);
    }
  };

  const createProblem = async (data: Problem) => {
    try {
      await axios.post('https://codeoceantech.pythonanywhere.com//api/problems/', data);
      fetchProblems(); // Refresh the list after creation
    } catch (error) {
      console.error('Error creating problem:', error);
    }
  };

  const updateParent = async (id: number, data: Partial<Parent>) => {
    try {
      await axios.put(`https://codeoceantech.pythonanywhere.com//api/parents/${id}/`, data);
      fetchParents(); // Refresh the list after update
    } catch (error) {
      console.error('Error updating parent:', error);
    }
  };
  const updateChildren = async (id: number, data: Partial<Child>) => {
    try {
      await axios.put(`https://codeoceantech.pythonanywhere.com//api/children/${id}/`, data);
      fetchChildren(); // Refresh the list after update
    } catch (error) {
      console.error('Error updating parent:', error);
    }
  };

  const updateInstructor = async (id: number, data: Partial<Instructor>) => {
    try {
      await axios.put(`https://codeoceantech.pythonanywhere.com//api/instructors/${id}/`, data);
      fetchInstructors(); // Refresh the list after update
    } catch (error) {
      console.error('Error updating instructor:', error);
    }
  };

  const updateCourse = async (id: number, data: Partial<Course>) => {
    try {
      await axios.put(`https://codeoceantech.pythonanywhere.com//api/courses-control/${id}/`, data);
      fetchCourses(); // Refresh the list after update
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };

  const updateSession = async (id: number, data: Partial<Session>) => {
    try {
      await axios.put(`https://codeoceantech.pythonanywhere.com//api/sessions/${id}/`, data);
      fetchSessions(); // Refresh the list after update
    } catch (error) {
      console.error('Error updating session:', error);
    }
  };

  const updateTask = async (id: number, data: Partial<Task>) => {
    try {
      await axios.put(`https://codeoceantech.pythonanywhere.com//api/tasks/${id}/`, data);
      fetchTasks(); // Refresh the list after update
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const updateTaskCompletion = async (id: number, data: Partial<TaskCompletion>) => {
    try {
      await axios.put(`https://codeoceantech.pythonanywhere.com//api/task-completions/${id}/`, data);
      fetchTaskCompletions(); // Refresh the list after update
    } catch (error) {
      console.error('Error updating task completion:', error);
    }
  };
  const updateFeedback = async (id: number, data: Partial<Feedback>, sessionId: number) => {
    try {
        // Include sessionId in the data object
        const updatedData = { ...data, session: sessionId };
        await axios.put(`https://codeoceantech.pythonanywhere.com//api/feedback/${id}/`, updatedData);
        fetchFeedbacks(); // Refresh the list after update
    } catch (error) {
        console.error('Error updating feedback:', error);
    }
};


  const updateProblem = async (id: number, data: Partial<Problem>) => {
    try {
      await axios.put(`https://codeoceantech.pythonanywhere.com//api/problems/${id}/`, data);
      fetchProblems(); // Refresh the list after update
    } catch (error) {
      console.error('Error updating problem:', error);
    }
  };

  const deleteParent = async (id: number) => {
    try {
      await axios.delete(`https://codeoceantech.pythonanywhere.com//api/parents/${id}/`);
      fetchParents(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting parent:', error);
    }
  };
  const deleteChildren = async (id: number) => {
    try {
      await axios.delete(`https://codeoceantech.pythonanywhere.com//api/children/${id}/`);
      fetchChildren(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting parent:', error);
    }
  };

  const deleteInstructor = async (id: number) => {
    try {
      await axios.delete(`https://codeoceantech.pythonanywhere.com//api/instructors/${id}/`);
      fetchInstructors(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting instructor:', error);
    }
  };

  const deleteCourse = async (id: number) => {
    try {
      await axios.delete(`https://codeoceantech.pythonanywhere.com//api/courses-control/${id}/`);
      fetchCourses(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  const deleteSession = async (id: number) => {
    try {
      await axios.delete(`https://codeoceantech.pythonanywhere.com//api/sessions/${id}/`);
      fetchSessions(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting session:', error);
    }
  };

  const deleteTask = async (id: number) => {
    try {
      await axios.delete(`https://codeoceantech.pythonanywhere.com//api/tasks/${id}/`);
      fetchTasks(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const deleteTaskCompletion = async (id: number) => {
    try {
      await axios.delete(`https://codeoceantech.pythonanywhere.com//api/task-completions/${id}/`);
      fetchTaskCompletions(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting task completion:', error);
    }
  };

  const deleteFeedback = async (id: number) => {
    try {
      await axios.delete(`https://codeoceantech.pythonanywhere.com//api/feedback/${id}/`);
      fetchFeedbacks(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting feedback:', error);
    }
  };

  const deleteProblem = async (id: number) => {
    try {
      await axios.delete(`https://codeoceantech.pythonanywhere.com//api/problems/${id}/`);
      fetchProblems(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting problem:', error);
    }
  };

  // Fetch initial data
  useEffect(() => {
    fetchParents();
    fetchChildren();
    fetchInstructors();
    fetchCourses();
    fetchSessions();
    fetchTasks();
    fetchTaskCompletions();
    fetchFeedbacks();
    fetchProblems();
  }, []);

  return (
    <ApiContext.Provider value={{
      parents,
      child,
      instructors,
      courses,
      sessions,
      tasks,
      taskCompletions,
      feedbacks,
      problems,
      fetchParents,
      fetchChildren,
      fetchInstructors,
      fetchCourses,
      fetchSessions,
      fetchSession,
      fetchTasks,
      fetchTaskCompletions,
      fetchFeedbacks,
      fetchProblems,
      createParent,
      createChildren,
      createInstructor,
      createCourse,
      createSession,
      createTask,
      createTaskCompletion,
      createFeedback,
      createProblem,
      updateParent,
      updateChildren,
      updateInstructor,
      updateCourse,
      updateSession,
      updateTask,
      updateTaskCompletion,
      updateFeedback,
      updateProblem,
      deleteParent,
      deleteChildren,
      deleteInstructor,
      deleteCourse,
      deleteSession,
      deleteTask,
      deleteTaskCompletion,
      deleteFeedback,
      deleteProblem
    }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};