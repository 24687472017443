import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import other components as needed...
import RequireAuth from '../context/RequireAuth';
import Colors from '../views/theme/colors/Colors';
import Typography from '../views/theme/typography/Typography';
import Accordion from '../views/base/accordion/Accordion';
import Cards from '../views/base/cards/Cards';
import Dashboard from '../views/pages/Dashboard';
import NotFound from '../views/base/NotFound/NotFound';
import Home from '../views/base/home/Home';
import Login from '../views/pages/Login';
import ParentComponentCourses from '../views/pages/Courses';
import ParentManagement from '../views/pages/ParentManagement';
import InstructorsTable from '../views/base/Instructors/InstructorsTable';
import UserProfile from '../views/pages/UserProfile'; // Import the User Profile component
import FeedbackControl from '../views/pages/FeedBacks';
import ProblemControl from '../views/pages/ProblemControl';
import NotifyList from '../views/pages/NotifyList';
import Payments from '../views/pages/Payments'; // Import the Payments component
import Children from '../views/pages/Children';

const AppContent = () => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/home"
          element={
            <RequireAuth requiredRole="/">
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="/notifylist"
          element={
            <RequireAuth requiredRole="/">
              <NotifyList />
            </RequireAuth>
          }
        />
        
        <Route
          path="/courses"
          element={
            <RequireAuth requiredRole="/">
              <ParentComponentCourses />
            </RequireAuth>
          }
        />
        <Route
          path="/parents"
          element={
            <RequireAuth requiredRole="/">
              <ParentManagement />
            </RequireAuth>
          }
        />
        <Route
          path="/children"
          element={
            <RequireAuth requiredRole="/">
              <Children />
            </RequireAuth>
          }
        />
        <Route
          path="/feedbacks"
          element={
            <RequireAuth requiredRole="/">
              <FeedbackControl />
            </RequireAuth>
          }
        />
        <Route
          path="/problems"
          element={
            <RequireAuth requiredRole="/">
              <ProblemControl />
            </RequireAuth>
          }
        />
        <Route
          path="/instructors"
          element={
            <RequireAuth requiredRole="/">
              <InstructorsTable />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard"
          element={
            <RequireAuth requiredRole="manager">
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/theme/colors"
          element={
            <RequireAuth requiredRole="manager">
              <Colors />
            </RequireAuth>
          }
        />
        <Route
          path="/theme/typography"
          element={
            <RequireAuth requiredRole="manager">
              <Typography />
            </RequireAuth>
          }
        />
        <Route
          path="/base/accordion"
          element={
            <RequireAuth requiredRole="manager">
              <Accordion />
            </RequireAuth>
          }
        />
        <Route
          path="/user"  // User Profile route
          element={
            <RequireAuth requiredRole="/">
              <UserProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/payments"  // New Payments route
          element={
            <RequireAuth requiredRole="/">
              <Payments />
            </RequireAuth>
          }
        />
        <Route
          path="*" 
          element={
            <RequireAuth requiredRole="manager">
              <NotFound />
            </RequireAuth>
          }
        />
      </Routes>
    </React.Suspense>
  );
};

export default AppContent;
